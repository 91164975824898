import { useApolloClient, useMutation, useQuery } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import {
	LoginMutation,
	LoginMutationVariables,
	LoginAsCustomerMutation,
	LoginAsCustomerMutationVariables,
	LogoutMutation,
	LogoutMutationVariables,
	ResetPasswordMutation,
	ResetPasswordMutationVariables,
	CheckPasswordResetHashQuery,
	CheckPasswordResetHashQueryVariables,
	CreateAccountInput,
	CreateAccountMutation,
	CreateAccountMutationVariables
} from '../../../../client/__generated__/graphql-client-types';
import {
	LOGIN,
	LOGIN_AS_CUSTOMER,
	LOGOUT,
	RESET_PASSWORD,
	CHECK_PASSWORD_RESET_HASH,
	CREATE_ACCOUNT
} from '../../../queries/authentication/authentication.queries';
import { LoginResponse, AuthenticatedFailure } from '../../../types/authentication.types';
import { useCustomer } from '../customer/customer.hooks';
import { useUpdateCustomer } from './update-customer.hook';

const GENERIC_AUTH_ERROR = 'Sorry, there was an error processing your request. Please try again.';

function isAuthFailure(response: LoginResponse | undefined): response is AuthenticatedFailure {
	return response?.__typename === 'AuthenticationFailure';
}

export const useCustomerLogin = () => {
	const [loginCustomer, { loading }] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);
	const updateCustomer = useUpdateCustomer();

	return {
		login: (userName: string, password: string, token?: string) => {
			return new Promise((resolve, reject) => {
				loginCustomer({ variables: { userName, password, token } })
					.then(async ({ data }) => {
						const authResponse = data?.login;
						if (!authResponse || isAuthFailure(authResponse)) {
							reject(authResponse?.message);
						} else {
							await updateCustomer(authResponse);
							resolve(true);
						}
					})
					.catch((message) => {
						logError(message);
						reject(GENERIC_AUTH_ERROR);
					});
			});
		},
		loading
	};
};

export const useInternalCustomerLogin = () => {
	const [loginAsCustomer, { loading }] = useMutation<LoginAsCustomerMutation, LoginAsCustomerMutationVariables>(LOGIN_AS_CUSTOMER);
	const updateCustomer = useUpdateCustomer();

	return {
		internalLogin: (customerId: number) => {
			return new Promise((resolve, reject) => {
				loginAsCustomer({ variables: { customerId } })
					.then(async ({ data }) => {
						const result = data?.loginAsCustomer;
						if (result?.__typename === 'AuthenticatedCustomer') {
							await updateCustomer(result, true, false, true);
							resolve(true);
						} else {
							reject(GENERIC_AUTH_ERROR);
						}
					})
					.catch((message) => {
						logError(message);
						reject(message);
					});
			});
		},
		loading
	};
};

export const useCustomerLogout = () => {
	const [logoutCustomer, { loading }] = useMutation<LogoutMutation, LogoutMutationVariables>(LOGOUT);
	const { cache } = useApolloClient();
	const customer = useCustomer().data?.customer;
	const updateCustomer = useUpdateCustomer();

	return {
		logout: async () => {
			// update auth status immediately before queries are refetched (occurs during cache reset)
			// this will ensure auth'd routes are unmounted first and their queries won't refetch
			// on cache reset (which will result in auth error on those queries) if a user logs
			// out while on an authenticated route.
			if (customer) {
				cache.modify({
					id: cache.identify(customer),
					fields: {
						isAuthenticated: () => false
					}
				});
			}

			const { data } = await logoutCustomer();
			if (data?.logout) {
				await updateCustomer({ token: data.logout }, false);
			} else {
				throw Error(GENERIC_AUTH_ERROR);
			}
		},
		loading
	};
};

export const useResetPassword = () => {
	const [resetPassword, { loading }] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(RESET_PASSWORD);
	const updateCustomer = useUpdateCustomer();

	return {
		resetPassword: (newPassword: string, hash: string) => {
			return new Promise((resolve, reject) => {
				resetPassword({ variables: { newPassword, hash } })
					.then(async ({ data }) => {
						const resetData = data?.resetPassword;
						if (resetData?.__typename === 'Error') {
							reject(resetData?.message);
						} else if (resetData?.__typename === 'AuthenticatedCustomer') {
							await updateCustomer(resetData);
							resolve(true);
						}
					})
					.catch((message) => {
						logError(message);
						reject(GENERIC_AUTH_ERROR);
					});
			});
		},
		loading
	};
};

export const useCheckPasswordResetHash = (hash: string) => {
	const { data, loading } = useQuery<CheckPasswordResetHashQuery, CheckPasswordResetHashQueryVariables>(CHECK_PASSWORD_RESET_HASH, {
		variables: { hash }
	});

	const isValidHash = Boolean(data?.checkPasswordResetHash?.success);

	return { isValidHash, loading };
};

export const useCreateAccount = () => {
	const [createAccount] = useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CREATE_ACCOUNT);
	const updateCustomer = useUpdateCustomer();
	return async (accountData: CreateAccountInput) => {
		const result = await createAccount({
			variables: { input: accountData }
		});
		if (result.data?.createAccount.__typename === 'AuthenticatedCustomer') {
			await updateCustomer(result.data.createAccount, true, true);
		}
		return result;
	};
};
